import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ReactQueryDevtools } from 'react-query-devtools';
import { PersistGate } from 'redux-persist/integration/react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import * as serviceWorker from './serviceWorker';
import { persistor, store } from './stores';
import App from './App';

import 'react-toastify/dist/ReactToastify.css';
import './styles/index.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#19499E',
      contrastText: '#fff',
    },
    secondary: {
      main: '#19499E',
      contrastText: '#fff',
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <App />
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.register();
