import React from 'react';
import { Link } from 'react-router-dom';
import { APP_TITTLE } from '../config';

export const Footer = () => {
  return (
    <footer className="text-sm text-center font-semibold pt-2 pb-4 px-6">
      <p>
        © 2020 {APP_TITTLE}. All rights reserved.{' '}
        <Link to="#" className="underline">
          Terms of Service
        </Link>{' '}
        |{' '}
        <Link to="#" className="underline">
          Privacy Policy
        </Link>
      </p>
    </footer>
  );
};
