import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import decode from 'jwt-decode';
import LoginPage from './pages/login';
import { getAccessToken, isAuthenticate, isTokenExpired, ITokenPayload } from './utils';
import { plmcApi } from './config';
import { detailsAction, logoutAction } from './stores/user/Actions';
// import LandingPage from './pages/landing';
// import SecondaryApp from './SecondaryApp';

const LandingPage = lazy(() => import('./pages/landing'));
const SecondaryApp = lazy(() => import('./SecondaryApp'));

export const PrivateRoute = ({ children, ...rest }: RouteProps) => (
  <Route
    {...rest}
    render={({ location }) =>
      isAuthenticate() ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: { pathname: location.pathname } },
          }}
        />
      )
    }
  />
);

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    async function getUser(token: string) {
      try {
        if (isTokenExpired()) {
          dispatch(logoutAction());
        } else {
          const decodedPayload = decode(token) as ITokenPayload;
          const res = await plmcApi.get(`/common/user?email=${decodedPayload.email}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          dispatch(
            detailsAction({
              userId: res.data.data.userId,
              email: res.data.data.email,
              firstName: res.data.data.firstName,
              lastName: res.data.data.lastName,
              phoneNumber: res.data.data.phoneNumber,
              role: res.data.data.role,
              enabled: res.data.data.enabled,
            }),
          );
        }
      } catch (error) {
        dispatch(logoutAction());
      }
    }

    const token = getAccessToken();
    if (token) {
      getUser(token);
    }
  }, [dispatch]);

  return (
    <Router>
      <Suspense fallback={<p>loading ...</p>}>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/login" render={() => (isAuthenticate() ? <Redirect to="/dashboard" /> : <LoginPage />)} />
          <PrivateRoute path="*">
            <SecondaryApp />
          </PrivateRoute>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
