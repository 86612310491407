import decode from 'jwt-decode';
import { store } from '../stores';
import { IUser } from '../stores/user/Reducer';

export const truncate = (str: string, n: number): string => (str.length > n ? str.substr(0, n - 1) + '...' : str);

export const msToMinutesAndSeconds = (ms: number | string): string => {
  ms = typeof ms === 'string' ? parseInt(ms, 10) : ms;
  const minutes = Math.floor(ms / 60000);
  const seconds = Math.floor((ms % 60000) / 1000);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
};

// all regex
export const emailRegex = /^([a-z\d._-]+)@([a-z\d_-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/i;
export const lowercaseRegex = /(?=.*[a-z])/;
export const uppercaseRegex = /(?=.*[A-Z])/;
export const numericRegex = /(?=.*[0-9])/;
export const specialCharRegex = /(?=.*[\^$*.[\]{}()?“!@#%&/,><’:;|_~`/-])/;

export interface ITokenPayload extends Pick<IUser, 'email' | 'role' | 'firstName' | 'lastName' | 'phoneNumber'> {
  id: string;
  sub: string;
  exp: number;
  iat: number;
}

// manage access token
export const setAccessToken = (token: string) => {
  window.localStorage.setItem('drd-acc', token);
};
export const removeAccessToken = () => {
  window.localStorage.removeItem('drd-acc');
};
export const getAccessToken = (): string | undefined => {
  let accessToken: string | undefined = undefined;
  if (typeof window !== undefined && window.localStorage.getItem('drd-acc')) {
    accessToken = window.localStorage.getItem('drd-acc')!;
  } else {
    removeAccessToken();
  }
  return accessToken;
};

// token expiry check
export const isTokenExpired = () => {
  try {
    const token = getAccessToken();
    if (!token) {
      return true;
    }

    const decoded = decode(token) as ITokenPayload;
    if (decoded.exp > Date.now() / 1000) {
      return false;
    } else return true;
  } catch (err) {
    return true;
  }
};

export const isAuthenticate = () => (store.getState().userReducer.user ? true : false);

export const headers = () => {
  if (!isTokenExpired()) {
    const token = getAccessToken()!;
    return {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
  } else {
    return {
      headers: {
        'Content-type': 'application/json',
      },
    };
  }
};
