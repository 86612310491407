import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, TextField } from '@material-ui/core';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { emailRegex, headers } from '../../utils';
import { DrdBackdrop } from '../../components/Backdrop';
import Logo from '../../resources/images/logo-lg.png';
import { useDispatch } from 'react-redux';
import { loginAction } from '../../stores/user/Actions';
import { InputHeader } from '../../components/InputHeader';
import { plmcApi } from '../../config';
import { Footer } from '../../components/Footer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      '& label.Mui-focused': {
        color: '#040b1c80',
      },
      '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
        color: '#000000',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#19499E',
        },
        '&:hover fieldset': {
          borderColor: '#19499E',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#19499E',
        },
      },
    },
  }),
);

const validationSchema = Yup.object().shape({
  email: Yup.string().trim().lowercase().required('Required').matches(emailRegex, 'Invalid email format'),
  password: Yup.string().trim().lowercase().required('Required').min(4, 'Minimum 4 characters required!'),
});

interface IData {
  email: string;
  password: string;
}

const LoginPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { from } = (location.state as {
    from: {
      pathname: string;
    };
  }) || { from: { pathname: '/dashboard' } };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [formError, setFormError] = useState<string>();

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<IData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async ({ email, password }) => {
    try {
      setFormError(undefined);
      const res = await plmcApi.post('/public/user/login', { email, password }, headers());

      dispatch(loginAction(res.data.data));
      history.replace(from);
    } catch (error) {
      const errMsg = error?.response?.data?.errorMessage || 'Something went wrong';
      setFormError(errMsg);
    }
  });

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-drdBlue to-blue-200 sm:px-6">
      <DrdBackdrop open={isSubmitting} />
      <div className="flex-grow flex flex-col items-center justify-center">
        <div className="sm:w-md">
          <div className=" p-6 sm:p-10 rounded-2xl" style={{ backgroundColor: 'rgba(255,255,255, 0.3)' }}>
            <div className="flex flex-col items-center mt-5 mb-8">
              <NavLink to="/">
                <img src={Logo} alt="drd large logo" />
              </NavLink>
              <h1 className="text-xl font-bold mt-8 mb-3 text-center">Welcome to The PLMC</h1>
              <p className="font-semibold text-center">Publishing, Licensing, & Metadata Collective</p>
            </div>
            <form onSubmit={onSubmit}>
              <div className="mb-5">
                <InputHeader label="Email" />
                <TextField
                  type="text"
                  name="email"
                  id="email"
                  fullWidth
                  variant="outlined"
                  className={classes.input}
                  placeholder="Email"
                  inputRef={register}
                  error={errors.email ? true : false}
                />
                {errors.email && <p className="text-red-600 text-xs italic font-medium mt-1">{errors.email.message}</p>}
              </div>
              <div className="mb-5">
                <InputHeader label="Password" />
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  fullWidth
                  variant="outlined"
                  className={classes.input}
                  placeholder="Password"
                  inputRef={register}
                  error={errors.password ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && (
                  <p className="text-red-600 text-xs italic font-medium mt-1">{errors.password.message}</p>
                )}
              </div>
              {formError && <p className="text-red-600 text-sm font-bold italic">{formError}</p>}
              <button type="submit" className="w-full bg-drdBlue text-white text-xs font-medium rounded-md py-4 mt-5">
                Login
              </button>
            </form>
            <div className="flex justify-between text-drdGray py-4">
              <Link to="#" className="font-medium text-sm mt-1">
                Forgot username?
              </Link>
              <Link to="/forget-password" className="font-medium text-sm mt-1">
                Forgot password?
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;
