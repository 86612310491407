import { IUser } from './Reducer';
import { USER_TYPES } from './Types';
import decode from 'jwt-decode';
import { ITokenPayload, removeAccessToken, setAccessToken } from '../../utils';

export const loginAction = (token: string) => {
  try {
    const decodedPayload = decode(token) as ITokenPayload;
    setAccessToken(token);
    const payload: IUser = {
      userId: decodedPayload.id,
      email: decodedPayload.email,
      firstName: decodedPayload.firstName,
      lastName: decodedPayload.lastName,
      phoneNumber: decodedPayload.phoneNumber,
      role: decodedPayload.role,
      enabled: true,
    };
    return { type: USER_TYPES.LOGIN, payload };
  } catch (error) {
    console.log('token error', error);
  }
};

export const detailsAction = (payload: IUser) => {
  return { type: USER_TYPES.DETAILS, payload };
};

export const logoutAction = () => {
  removeAccessToken();
  return { type: USER_TYPES.LOGOUT };
};
